<template>
  <v-sheet class="pa-12 form-body__wrapper" color="lighten-3">
    <v-card>
      <div class="d-flex">
        <h1 class="text-h1 text-left pl-12 pb-6 pt-6">
          {{ translations.pageTitles.mediaLib }}
        </h1>
        <a  :href="helpLink" target="_blank" class="pt-6 pl-2" title="See the documentation"><v-icon color="blue darken-2" v-if="helpLink">mdi-help-circle</v-icon></a>
      </div>

      <v-divider inset></v-divider>

      <br/>

      <div class="pa-6">
        <v-card>
          <MediaLib
            :multiple="true"
            :show-trash-folder="true"
          />
        </v-card>
      </div>

    </v-card>
  </v-sheet>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import MediaLib from "@/components/medialib/MediaLib";

@Component({
  name: "Media",
  components: {
    MediaLib
  },
  props: {

  }
})
export default class Media extends Vue {
  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get helpLink() {
    return this.translations.pageHelp.mediaLibrary;
  }
}
</script>

<style lang="scss" scoped></style>